import "./styles.scss"

import React from "react"

const HeroFinished = ({ count }) => {
  return (
    <section
      className="investments-hero-finished"
      style={{
        backgroundImage: `url('${
          require("assets/images/investments/investment-hero-background-finished.jpg")
            .default
        }')`,
      }}
    >
      <div className="container-fluid">
        <div className="investments-hero-finished__wrapper">
          <span>{count}</span>
          <small>zrealizowanych inwestycji</small>
          <ul>
            <li>Atrakcyjna lokalizacja nieruchomości</li>
            <li>Wysoka jakość wykonania</li>
            <li>Funkcjonalność i wygoda</li>
            <li>Zgodnie z najnowszymi trendami w architekturze</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default HeroFinished
