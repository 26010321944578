import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

const FinancingForm = () => {
  const formID = "163"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)
  const [legal2, setLegal2] = useState(false)
  const [legal3, setLegal3] = useState(false)
  const [legal4, setLegal4] = useState(false)

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("legalFirst", legalFirst)
    formData.set("pageurl", location)

    axios({
      method: "post",
      url: `https://wp.marshallre.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success")
        navigate("/kontakt-potwierdzenie/")
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={formSubmit}>
      <Input
        placeholder="Imię i nazwisko"
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div className="row">
        <div className="col-sm">
          <Input
            placeholder="Telefon"
            type="phone"
            id="phone"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </div>
        <div className="col-sm">
          <Input
            placeholder="Email*"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
      </div>
      <Checkbox
        name="legalFirst"
        checked={legalFirst}
        onChange={() => setLegalFirst(!legalFirst)}
        required
      >
        * Oświadczam, że zapoznałem/am się z Regulaminem serwisu i akceptuję
        jego treść.
      </Checkbox>
      <Checkbox
        name="legal2"
        checked={legal2}
        onChange={() => setLegal2(!legal2)}
      >
        Wyrażam zgodę na przetwarzanie moich danych osobowych w celach
        marketingowych tj. prezentacji ofert Administratora i podmiotów z nim
        współpracujących (podstawa prawna :art 6 ust.1 a RODO ).
      </Checkbox>
      <Checkbox
        name="legal3"
        checked={legal3}
        onChange={() => setLegal3(!legal3)}
      >
        Wyrażam zgodę na przesyłanie drogą elektroniczną informacji handlowych
        zgodnie z ustawą z dnia 18 lipca 2002 r. ze zm. o świadczeniu usług
        drogą elektroniczną (Dz.U.2020.0.344) na podany adres e-mail.
      </Checkbox>
      <Checkbox
        name="legal4"
        checked={legal4}
        onChange={() => setLegal4(!legal4)}
      >
        Wyrażam zgodę na kontakt telefoniczny w celu prezentacji ofert zgodnie z
        ustawą z dnia 16 lipca 2004 r. ze zmianami Prawo telekomunikacyjne
        (Dz.U.2019.0.2460).
      </Checkbox>

      <div className="contact-global__submit">
        <Button type="submit" disabled={!legalFirst}>
          {send === true ? "Wysyłanie.." : "Wyślij"}
        </Button>
      </div>
    </form>
  )
}

export default FinancingForm
