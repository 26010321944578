import "./styles.scss"

import React from "react"

import InvestmentTile from "components/InvestmentTile"

const Listing = ({ data, query }) => {
  return (
    <div className="investments-listing">
      {data
        ?.filter(
          ({ title }) =>
            title.includes(query) || title.toLowerCase().includes(query)
        )
        .map((item, index) => (
          <InvestmentTile item={item} key={index} />
        ))}

      {data?.filter(
        ({ title }) =>
          title.includes(query) || title.toLowerCase().includes(query)
      ).length === 0 && (
        <p className="investments-listing__info">Brak wyników..</p>
      )}
    </div>
  )
}

export default Listing
