import "./styles.scss"

import React, { useState, useEffect } from "react"

const Hero = ({
  background,
  icon,
  description,
  investemntsLenght,
  allInvestemnts,
}) => {
  const [apartments, setApartments] = useState(null)

  useEffect(() => {
    allInvestemnts?.length > 0 &&
      setApartments(
        allInvestemnts
          .map(item => item?.acfInvestment?.apartmensNumber)
          .reduce((sum, num) => sum + num)
      )
  }, [allInvestemnts])

  return (
    <section
      className="investments-hero"
      style={{ backgroundImage: `url('${background}')` }}
    >
      <div className="container-fluid">
        <div className="investments-hero__wrapper">
          <img src={icon} alt="" />
          <ul>{description}</ul>
          <div className="investments-hero__wrapper-row">
            <div>
              <span>{investemntsLenght}</span>
              <small>inwestycji</small>
            </div>
            <div>
              <span>{apartments || 0}</span>
              <small>apartamentów</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
