import React, { useState } from "react"
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api"
import { withPrefix } from "gatsby"

import InvestmentMapItem from "components/InvestmentMapItem"

import mapStyle from "utils/mapStyle"

const center = {
  lat: 52.0497363,
  lng: 19.2851127,
}

const InvestmentsMap = ({ data }) => {
  const isBrowser = typeof window !== "undefined"
  const [infoWindow, setInfoWindow] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDjIwkgdwELw8D7YSGfklubIKQyUl-OQy4",
  })

  return (
    <div className="investments-map">
      {isLoaded ? (
        <div id="map">
          <GoogleMap
            center={center}
            zoom={isBrowser && window.innerWidth > 991 ? 7 : 6}
            options={{ styles: mapStyle }}
          >
            {data?.map((item, index) => (
              <Marker
                onClick={() => setInfoWindow(item?.id)}
                key={index}
                icon={withPrefix("/ico-pointer.svg")}
                position={{
                  lat: item?.acfInvestment?.mapContent?.latitude,
                  lng: item?.acfInvestment?.mapContent?.longitude,
                }}
              />
            ))}

            {data?.map((item, index) => (
              <Marker
                onClick={() => setInfoWindow(item?.id)}
                key={index}
                icon={withPrefix("/ico-pointer.svg")}
                position={{
                  lat: item?.acfInvestment?.mapContent?.latitude,
                  lng: item?.acfInvestment?.mapContent?.longitude,
                }}
              />
            ))}

            {data
              ?.filter(item => item.id === infoWindow)
              .map((item, index) => (
                <InfoWindow
                  key={index}
                  onCloseClick={() => setInfoWindow(null)}
                  position={{
                    lat: item?.acfInvestment?.mapContent?.latitude,
                    lng: item?.acfInvestment?.mapContent?.longitude,
                  }}
                >
                  <InvestmentMapItem item={item} />
                </InfoWindow>
              ))}
          </GoogleMap>
        </div>
      ) : null}
    </div>
  )
}

export default InvestmentsMap
