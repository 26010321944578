import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import LazyFadeImage from "components/LazyFadeImage"

const Grid = () => {
  const data = [
    {
      title: "Góry",
      path: "/portfel-nieruchomosci/inwestycje-w-gorach/",
      background:
        require("assets/images/investments/investment-grid-background-mountains.jpg")
          .default,
    },
    {
      title: "Morze",
      path: "/portfel-nieruchomosci/inwestycje-nad-morzem/",
      background:
        require("assets/images/investments/investment-grid-background-sea.jpg")
          .default,
    },
    {
      title: "Warmia i Mazury",
      path: "/portfel-nieruchomosci/warmia-i-mazury/",
      background:
        require("assets/images/investments/investment-grid-background-lakes.jpg")
          .default,
    },
    {
      title: "Inwestycje miejskie",
      path: "/portfel-nieruchomosci/inwestycje-miejskie/",
      background:
        require("assets/images/investments/investment-grid-background-cities.jpg")
          .default,
    },
    {
      title: "Inwestycje zagraniczne",
      path: "/portfel-nieruchomosci/inwestycje-zagraniczne/",
      background: require("assets/images/investments/Inwestycje-hiszpania.jpg")
        .default,
    },
    {
      title: "Zrealizowane projekty",
      path: "/portfel-nieruchomosci/zrealizowane-projekty/",
      background:
        require("assets/images/investments/investment-grid-background-finished.jpg")
          .default,
    },
  ]

  return (
    <section className="investments-grid">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {data.map((item, index) => (
            <div
              className={index === 5 ? "col-lg-12" : "col-lg col-smd-6"}
              key={index}
            >
              <Link
                to={item.path}
                className={`investments-grid__item investments-grid__item--${index}`}
              >
                <LazyFadeImage>
                  <div
                    className="investments-grid__item-background"
                    style={{ backgroundImage: `url('${item.background}')` }}
                  ></div>
                </LazyFadeImage>
                <h3>{item.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Grid
