import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"
import Select from "components/Select"

const Form = ({ slug, partialSubjects, dataFavCompare, investment }) => {
  const formID = "8"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)
  const [legal2, setLegal2] = useState(false)
  const [legal3, setLegal3] = useState(false)
  const [legal4, setLegal4] = useState(false)
  const [file, setFile] = useState(null)

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("subject", subject)
    formData.set("message", message)
    formData.set("legalFirst", legalFirst)
    formData.set("pageurl", location)
    formData.set("file", file ? file[0] : null)
    formData.set(
      "favCompare",
      dataFavCompare ? dataFavCompare?.map(item => item.title) : null
    )

    axios({
      method: "post",
      url: `https://wp.marshallre.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success")
        navigate(slug ? `/dziekujemy-${slug}/` : "/kontakt-potwierdzenie/")

        investment &&
          isBrowser &&
          window.dataLayer.push({
            event: "form-contact-investment-bottom",
            type: investment,
          })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const mainSubjects = [
    { label: "Kontakt w sprawie", value: "", disabled: true },
    { label: "Zakup apartamentu", value: "Zakup apartamentu" },
    {
      label: "Zarządzanie apartamentem",
      value: "Zarządzanie apartamentem",
    },
    {
      label: "Zwrot VAT z zakupu nieruchomości",
      value: "Zwrot VAT z zakupu nieruchomości",
    },
  ]

  const additionalSubjects = [
    {
      label: "Współpraca przy sprzedaży projektu deweloperskiego",
      value: "Współpraca przy sprzedaży projektu deweloperskiego",
    },
    {
      label: "Kariera (prosimy o dołączenie CV)",
      value: "Kariera (prosimy o dołączenie CV)",
    },
  ]

  const subjects = partialSubjects
    ? [...mainSubjects]
    : [...mainSubjects, ...additionalSubjects]

  return (
    <form onSubmit={formSubmit} className="form">
      <Input
        placeholder="Imię i nazwisko"
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div className="row">
        <div className="col-md">
          <Input
            placeholder="Telefon*"
            type="phone"
            id="phone"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="col-md">
          <Input
            placeholder="Email*"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
      </div>
      <Select
        id="subject"
        name="subject"
        value={subject}
        onChange={e => setSubject(e.target.value)}
        className="select--background"
        options={subjects}
      />
      <Input
        placeholder="Treść wiadomości"
        type="textarea"
        id="message"
        name="message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        rows="6"
      />

      {subject === "Kariera (prosimy o dołączenie CV)" && (
        <div className="form__file">
          <input
            type="file"
            id="upload"
            name="upload"
            onChange={e => setFile(e.target.files)}
            style={{ display: "none" }}
          />
          <label htmlFor="upload">
            <svg viewBox="0 0 60 60">
              <g>
                <circle cx="30" cy="30" r="30" stroke="none" />
                <circle cx="30" cy="30" r="29.5" fill="none" />
              </g>
              <path
                d="M1511,1099.5v13h-13"
                transform="translate(-1481.307 -1082)"
              />
              <path
                d="M1511,1099.5v13h-13"
                transform="translate(1540.693 1143) rotate(180)"
              />
            </svg>
            {file && file.length > 0 ? (
              <p>{file[0] && file[0].name}</p>
            ) : (
              <p>Dodaj załącznik CV</p>
            )}
          </label>
        </div>
      )}

      <Checkbox
        name="legalFirst"
        checked={legalFirst}
        onChange={() => setLegalFirst(!legalFirst)}
        required
      >
        * Oświadczam, że zapoznałem/am się z Regulaminem serwisu i akceptuję
        jego treść.
      </Checkbox>
      <Checkbox
        name="legal2"
        checked={legal2}
        onChange={() => setLegal2(!legal2)}
      >
        Wyrażam zgodę na przetwarzanie moich danych osobowych w celach
        marketingowych tj. prezentacji ofert Administratora i podmiotów z nim
        współpracujących (podstawa prawna :art 6 ust.1 a RODO ).
      </Checkbox>
      <Checkbox
        name="legal3"
        checked={legal3}
        onChange={() => setLegal3(!legal3)}
      >
        Wyrażam zgodę na przesyłanie drogą elektroniczną informacji handlowych
        zgodnie z ustawą z dnia 18 lipca 2002 r. ze zm. o świadczeniu usług
        drogą elektroniczną (Dz.U.2020.0.344) na podany adres e-mail.
      </Checkbox>
      <Checkbox
        name="legal4"
        checked={legal4}
        onChange={() => setLegal4(!legal4)}
      >
        Wyrażam zgodę na kontakt telefoniczny w celu prezentacji ofert zgodnie z
        ustawą z dnia 16 lipca 2004 r. ze zmianami Prawo telekomunikacyjne
        (Dz.U.2019.0.2460).
      </Checkbox>

      <div className="contact-global__submit">
        <Button type="submit" disabled={!legalFirst}>
          {send === true ? "Wysyłanie.." : "Wyślij"}
        </Button>
      </div>
    </form>
  )
}

export default Form
