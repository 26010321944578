import "./styles.scss"

import React, { useState } from "react"

import Button from "components/Button"
import Input from "components/Input"

import { Listing } from "./components"
import InvestmentsMap from "components/InvestmentsMap"

const Content = ({ data }) => {
  const [view, setView] = useState("list")
  const [query, setQuery] = useState("")

  return (
    <div className="investments-content">
      <div className="container-fluid">
        {data.length ? (
          <>
            <div className="investments-content__row">
              {view === "list" ? (
                <div className="investments-content__col">
                  <div className="investments-content__search">
                    <Input
                      label="Nazwa inwestycji"
                      placeholder="Szukaj"
                      className="no-m"
                      value={query}
                      onChange={({ target: { value } }) => setQuery(value)}
                    />
                  </div>
                </div>
              ) : (
                <h3>Mapa inwestycji</h3>
              )}

              <div className="investments-content__cta">
                <Button
                  className={view !== "list" ? "button--bordered" : ""}
                  onClick={() => setView("list")}
                >
                  Widok listy
                </Button>
                <Button
                  className={view !== "map" ? "button--bordered" : ""}
                  onClick={() => setView("map")}
                >
                  Widok mapy
                </Button>
              </div>
            </div>
            {view === "list" ? (
              <Listing data={data} query={query} />
            ) : (
              <InvestmentsMap data={data} />
            )}
          </>
        ) : (
          <p className="investments-content__info">Brak wyników..</p>
        )}
      </div>
    </div>
  )
}

export default Content
