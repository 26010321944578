import "./styles.scss"

import React from "react"

import ContactForm from "components/ContactForm"
import FinancingForm from "components/FinancingForm"

import IcoFacebook from "assets/icons/facebook.svg"
import IcoLinkedin from "assets/icons/linkedin.svg"

const ContactGlobal = ({
  header,
  slug,
  partialSubjects,
  formFinancing,
  investment,
}) => {
  return (
    <>
      <span className="anchor" id="contact"></span>
      <section className="contact-global">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-5">
              <h3>{header ? header : "A Ty jakiej nieruchomości szukasz?"}</h3>
              <div className="contact-global__info">
                <img
                  src={require("assets/images/logo-marshall.svg").default}
                  alt=""
                />
                <div>
                  <p>
                    Skontaktuj się z nami telefoniczne bądź mailowo. Spotkajmy
                    się w naszym biurze lub na terenie inwestycji. Chętnie
                    odpowiemy na wszystkie Twoje pytania i pomożemy Ci w wyborze
                    wymarzonej nieruchomości.
                  </p>
                  <a href="mailto:kontakt@marshallre.pl">
                    <h3>kontakt@marshallre.pl</h3>
                  </a>
                  <a href="tel:+48 577 080 006">
                    <h3>+48 577 080 006</h3>
                  </a>
                </div>
              </div>
              <address>
                <small>Biuro sprzedaży</small>
                ul. Poznańska 11, lokal 5B
                <br />
                00-682 Warszawa
              </address>
              <div className="contact-global__social">
                <small>obserwuj nas</small>
                <a
                  href="https://www.facebook.com/marshallrealestate.polska"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IcoFacebook} alt="" /> facebook
                </a>
                <a
                  href="https://www.linkedin.com/company/marshallre/?originalSubdomain=pl"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IcoLinkedin} alt="" /> linkedin
                </a>
              </div>
            </div>

            <div className="col-lg-6 offset-lg-1 col-sm-7">
              <h3>Skontaktuj się z nami</h3>
              {formFinancing ? (
                <FinancingForm />
              ) : (
                <ContactForm
                  slug={slug}
                  partialSubjects={partialSubjects}
                  investment={investment}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactGlobal
